import {Component, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {AnimationItem} from 'lottie-web';

@Component({
  selector: 'app-what-is-problem',
  templateUrl: './what-is-problem.component.html',
  styleUrls: ['./what-is-problem.component.scss']
})
export class WhatIsProblemComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/animations/home-page/what-is-problem/data.json'
  };

  animationCreated(animationItem: AnimationItem): void {
  }

  constructor() {
  }

  ngOnInit() {
  }

}
