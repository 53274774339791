import {Component, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {AnimationItem} from 'lottie-web';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-what-is',
  templateUrl: './home-what-is.component.html',
  styleUrls: ['./home-what-is.component.scss']
})
export class HomeWhatIsComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/animations/home-page/what-is-solution/data.json'
  };

  animationCreated(animationItem: any): void {
    animationItem.playSpeed = 0.7;
  }

  constructor(private route: Router) {}

  ngOnInit() {
  }

  public goToContactUsTop() {
    this.route.navigate(['/contact-us'], { fragment: 'contactUsTop' });
  }
}
