import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('getInTouch', {read:  ElementRef, static: false}) public getInTouch: ElementRef;
  private desrtoy$: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute) {
  }

  public ngOnDestroy(): void {
    this.desrtoy$.next();
    this.desrtoy$.complete();
  }

  public ngAfterViewInit(): void {
    this.route.fragment
      .pipe(takeUntil(this.desrtoy$))
      .subscribe(fragment => {
        if (fragment === 'getInTouch') {
          this.getInTouch.nativeElement.scrollIntoView(
            {block: 'start',
              inline: 'start',
            }
          );
        }
      });
  }
}
