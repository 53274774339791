import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-our-company',
  templateUrl: './about-our-company.component.html',
  styleUrls: ['./about-our-company.component.scss']
})
export class AboutOurCompanyComponent implements OnInit {

  constructor(private route: Router) {}

  ngOnInit() {
  }

  public goToGetInTouch(): void {
    this.route.navigate(['/contact-us'], { fragment: 'getInTouch' });
  }
}
