import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
  }

  public goToPartners() {
    this.route.navigate(['/about-us'], { fragment: 'partners' });
  }

  public goToGetInTouch() {
    this.route.navigate(['/contact-us'], { fragment: 'getInTouch' });
  }
}
