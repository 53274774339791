import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appDisableAnimation]'
})
export class DisableAnimationDirective {

  constructor(private element: ElementRef) {
    if(window.innerWidth < 700){
      this.element.nativeElement.removeAttribute('data-aos');
    }
  }
}
