import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-we-offer',
  templateUrl: './home-we-offer.component.html',
  styleUrls: ['./home-we-offer.component.scss']
})
export class HomeWeOfferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
