import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-partners-cards',
  templateUrl: './partners-cards.component.html',
  styleUrls: ['./partners-cards.component.scss']
})
export class PartnersCardsComponent implements OnInit {
  cards = [];

  constructor() {
    this.cards = [
      {
        title: 'ABOUT_US.CARDS.CARD_1_TITLE',
        description: 'ABOUT_US.CARDS.CARD_1_DESC',
        imgSrc: '../../../../../../assets/icons/about-us/card-icon1.svg',
        imgSize: '97'
      },
      {
        title: 'ABOUT_US.CARDS.CARD_2_TITLE',
        description: 'ABOUT_US.CARDS.CARD_2_DESC',
        imgSrc: '../../../../../../assets/icons/about-us/card-icon2.svg',
        imgSize: '114'
      },
      {
        title: 'ABOUT_US.CARDS.CARD_3_TITLE',
        description: 'ABOUT_US.CARDS.CARD_3_DESC',
        imgSrc: '../../../../../../assets/icons/about-us/card-icon3.svg',
        imgSize: '128'
      }
    ];
  }

  ngOnInit() {
  }
}
