import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-we-offer-cards',
  templateUrl: './we-offer-cards.component.html',
  styleUrls: ['./we-offer-cards.component.scss']
})
export class WeOfferCardsComponent implements OnInit {
  @ViewChild('wrap', {static: false}) wrap;
  cards = [];
  currentIndex = 0;

  constructor() {
    this.cards = [
      {
        title: 'HOME_CARDS.CARD_1_TITLE',
        description: 'HOME_CARDS.CARD_1_DESC',
        imgSrc: '../../../../../../assets/icons/home/what-is-icon1.svg'
      },
      {
        title: 'HOME_CARDS.CARD_2_TITLE',
        description: 'HOME_CARDS.CARD_2_DESC',
        imgSrc: '../../../../../../assets/icons/home/what-is-icon2.svg'
      },
      {
        title: 'HOME_CARDS.CARD_3_TITLE',
        description: 'HOME_CARDS.CARD_3_DESC',
        imgSrc: '../../../../../../assets/icons/home/what-is-icon3.svg'
      }
    ];
  }

  ngOnInit() {
  }

  changeSlide(index) {
    this.wrap.nativeElement.style.transform = `translate(-${index}00%)`;
    this.currentIndex = index;
  }

}
