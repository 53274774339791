import {Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-about-partners',
  templateUrl: './about-partners.component.html',
  styleUrls: ['./about-partners.component.scss']
})
export class AboutPartnersComponent implements AfterViewInit, OnDestroy {
  // private fragment: string;
  @ViewChild('partners', {read:  ElementRef, static: false}) public partners: ElementRef;
  private desrtoy$: Subject<void> = new Subject<void>();
  constructor(private route: ActivatedRoute) { }

  public ngOnDestroy(): void {
    this.desrtoy$.next();
    this.desrtoy$.complete();
  }

  public ngAfterViewInit(): void {
    this.route.fragment
      .pipe(takeUntil(this.desrtoy$))
      // .subscribe(fragment => { this.fragment = fragment; });
      .subscribe(fragment => {
        if (fragment === 'partners') {
          this.partners.nativeElement.scrollIntoView(
            {block: 'start',
              inline: 'start',
            }
          );
        }
      });
  }
}
