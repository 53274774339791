import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home-page/home.component';
import {HomeTopComponent} from './home-page/home-top/home-top.component';
import {RouterModule, Routes} from '@angular/router';
import {HomeVideoComponent} from './home-page/home-video/home-video.component';
import {HomeWhatIsComponent} from './home-page/home-what-is/home-what-is.component';
import {HomeWeOfferComponent} from './home-page/home-we-offer/home-we-offer.component';
import {WeOfferCardsComponent} from './home-page/home-we-offer/we-offer-cards/we-offer-cards.component';
import {HomeNewsFeedComponent} from './home-page/home-news-feed/home-news-feed.component';
import {HowItWorksComponent} from './how-it-works/how-it-works.component';
import {HowItTopComponent} from './how-it-works/how-it-top/how-it-top.component';
import {HowItMonetiseComponent} from './how-it-works/how-it-monetise/how-it-monetise.component';
import {HowItVaultComponent} from './how-it-works/how-it-vault/how-it-vault.component';
import {HowItDiscoverComponent} from './how-it-works/how-it-discover/how-it-discover.component';
import {HowItWalletComponent} from './how-it-works/how-it-wallet/how-it-wallet.component';
import {SharedModule} from '../../_shared/shared.module';
import {AboutUsComponent} from './about-us/about-us.component';
import {AboutOurCompanyComponent} from './about-us/about-our-company/about-our-company.component';
import {AboutPartnersComponent} from './about-us/about-partners/about-partners.component';
import {PartnersCardsComponent} from './about-us/about-partners/partners-cards/partners-cards.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {ContactTopComponent} from './contact-us/contact-top/contact-top.component';
import {ContactFormComponent} from './contact-us/contact-form/contact-form.component';
import {LottieModule} from 'ngx-lottie';
import {ComingSoonComponent} from './coming-soon/coming-soon.component';
import {WhatIsProblemComponent} from './home-page/home-what-is/what-is-problem/what-is-problem.component';
import {ReactiveFormsModule} from '@angular/forms';
import * as AOS from 'aos';
import {TranslateModule} from '@ngx-translate/core';

const mainRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'how-it-works', component: HowItWorksComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'soon', component: ComingSoonComponent}
];


@NgModule({
  declarations: [
    HomeComponent,
    HomeTopComponent,
    HomeVideoComponent,
    HomeWhatIsComponent,
    HomeWeOfferComponent,
    WeOfferCardsComponent,
    HomeNewsFeedComponent,
    HowItWorksComponent,
    HowItTopComponent,
    HowItMonetiseComponent,
    HowItVaultComponent,
    HowItDiscoverComponent,
    HowItWalletComponent,
    AboutUsComponent,
    AboutOurCompanyComponent,
    AboutPartnersComponent,
    PartnersCardsComponent,
    ContactUsComponent,
    ContactTopComponent,
    ContactFormComponent,
    ComingSoonComponent,
    WhatIsProblemComponent

  ],
  imports: [
    CommonModule,
    RouterModule.forChild(mainRoutes),
    SharedModule,
    LottieModule,
    ReactiveFormsModule,
    TranslateModule
  ],
})
export class MainModule {
  constructor() {
    AOS.init();
  }
}
