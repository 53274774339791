import {NgModule} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {RouterModule} from '@angular/router';
import {CardComponent} from './components/card/card.component';
import {CommonModule} from '@angular/common';
import {DisableAnimationDirective} from './directives/disable-animation.directive';
import {NgxFlagPickerModule} from 'ngx-flag-picker';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CardComponent,
    DisableAnimationDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgxFlagPickerModule,
    TranslateModule
  ],
  exports: [
    HeaderComponent,
    CardComponent,
    FooterComponent,
    DisableAnimationDirective,
    NgxFlagPickerModule
  ]
})
export class SharedModule {
}
