import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isOpenedMenu = true;
  headerLinks: any = [];
  selectedCountryCode = 'us';
  countryCodes = ['us', 'ua', 'fr', 'ar'];

  constructor(private translate: TranslateService) {
    this.headerLinks = [
      {
        title: 'Home',
        link: '/'
      },
      {
        title: 'HEADER.HOW_IT_WORKS',
        link: '/how-it-works'
      },
      {
        title: 'HEADER.ABOUT_US',
        link: '/about-us'
      },
      {
        title: 'HEADER.CONTACT_US',
        link: '/contact-us'
      }
    ];
  }

  ngOnInit() {
  }

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
    this.translate.use(value);
    if (value === 'ar') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
  }
}
