import {Component, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {Router} from '@angular/router';

@Component({
  selector: 'app-how-it-top',
  templateUrl: './how-it-top.component.html',
  styleUrls: ['./how-it-top.component.scss']
})
export class HowItTopComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/animations/how-it-works/how-it-top/data.json'
  };

  constructor(private route: Router) {
  }

  ngOnInit() {
  }

  animationCreated(animationItem: any): void {
    animationItem.playSpeed = 1;
  }

  public gotToGetInTouch() {
    this.route.navigate(['/contact-us'], {fragment: 'getInTouch'});
  }
}
